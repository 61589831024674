import React from "react"

import Container from "../components/container/container"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="Kiitos viestistäsi" />
    <Container>
      <h1>Kiitos viestistäsi</h1>
      <p>Olemme sinuun yhteydessä mahdollisimman pikaisesti.</p>
      <p><a style={{color : "#A88613"}} href="/">Palaa sivustolle</a></p>
    </Container>
  </Layout>
)

export default NotFoundPage
 